import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

// Troca de parceiros
export default class PartnerExchangeController {
  private _postExchangeEcopoint(data: any) {
    return axios.post(`${API_URL}/exchangeEcopoint`, data);
  }

  private _getCatalogPartnerByUser(partnerId: number) {
    return axios.get(`${API_URL}/catalogPartner/partnerId/${partnerId}`);
  }

  private getPartnerExchangeTransactions(catalogPartnerId: number) {
    return axios.get(
      `${API_URL}/exchangeEcopoint/catalogPartner/${catalogPartnerId}`
    );
  }
}
