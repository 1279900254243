
import loading from "vue3-loading-overlay";
import { Vue, Options } from "vue-class-component";
import CarbonController from "@/AppClubCarby/Controller/CarbonController";
import { removeCurrency, formatNumber } from "@/mixins";

@Options({
  components: {
    loading,
  },
  data() {
    return {
      carbonController: new CarbonController(),
      carbonParameters: {
        taxMargin: null,
        carbonSalePriceTotal: null,
        carbyMargin: null,
        purchasePrice: null,
      },
      loading: true,
    };
  },
  mounted() {
    this.getCarbonParameters();
  },
  methods: {
    /*     async setCarbonParameters() {
      this.loading = true;
      await this.carbonController.getCarbonHistoric().then((data) => {
        console.log(data);
        this.id = data.data.totalElements;
        this.getCarbonParameters();
        this.loading = false;
      });
    }, */
    async getCarbonParameters() {
      return await this.carbonController.getCarbonLastItem().then((data) => {
        console.log(data);
        this.carbonParameters = data.data;
        this.removeMask();
        this.loading = false;
      });
    },
    async updateValue(data) {
      this.loading = true;
      this.removeMaskToSend();

      return await this.carbonController.setCarbonHistoric(data).then(() => {
        this.getCarbonParameters();
        this.loading = false;
      });
    },
    removeMask() {
      this.carbonParameters.taxMargin = this.returnFormatNumber(
        this.carbonParameters.taxMargin
      );
      this.carbonParameters.carbonSalePriceTotalInKg = this.returnFormatNumber(
        this.carbonParameters.carbonSalePriceTotalInKg
      );
      this.carbonParameters.carbyMargin = this.returnFormatNumber(
        this.carbonParameters.carbyMargin
      );
      this.carbonParameters.purchasePriceInKg = this.returnFormatNumber(
        this.carbonParameters.purchasePriceInKg
      );
    },
    removeMaskToSend() {
      this.carbonParameters.taxMargin = parseFloat(
        this.returnCurrency(this.carbonParameters.taxMargin)
      );
      this.carbonParameters.carbonSalePriceTotalInKg = parseFloat(
        this.returnCurrency(this.carbonParameters.carbonSalePriceTotalInKg)
      );
      this.carbonParameters.carbyMargin = parseFloat(
        this.returnCurrency(this.carbonParameters.carbyMargin)
      );
      this.carbonParameters.purchasePriceInKg = parseFloat(
        this.returnCurrency(this.carbonParameters.purchasePriceInKg)
      );
    },
    returnFormatNumber(number) {
      return formatNumber(number);
    },
    returnCurrency(currency) {
      return removeCurrency(currency);
    },
  },
})
export default class AppCarbon extends Vue {}
