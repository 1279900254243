
import loading from "vue3-loading-overlay";
import { Vue, Options } from "vue-class-component";
import MethodologyController from "@/AppClubCarby/Controller/MethodologyController";
import {
  formatCurrency,
  formatCurrencyWithoutSymbol,
  removeCurrency,
  formatNumber,
  formatReal,
} from "@/mixins";

@Options({
  data() {
    return {
      methodologyController: new MethodologyController(),
      fuelCompensations: {
        emissionDiesel: null,
        emissionEthanol: null,
        emissionEthanolAdditive: null,
        emissionGasoline: null,
        emissionGasolineAdditive: null,
        renewablerDiesel: null,
        renewablerEthanol: null,
        renewablerEthanolAdditive: null,
        renewablerGasoline: null,
        renewablerGasolineAdditive: null,
      },
      isLoading: true,
      id: null,
    };
  },
  components: {
    loading,
  },
  mounted() {
    this.setCompensation();
    this.formatValue();
  },
  methods: {
    async setCompensation() {
      this.isLoading = true;
      await this.methodologyController.getMethodology().then((data) => {
        this.id = data.totalElements;
        this.getCompensation();
      });

      this.isLoading = false;
    },
    formatValue(value) {
      value = value + "";
      let formatedValue = value.replace(".", ",");
      formatedValue = formatedValue.split("");
      console.log(formatedValue);
      if (formatedValue.length == 3) {
        formatedValue.push("0");
      } else if (formatedValue.length === 1) {
        formatedValue.push(",00");
      }
      formatedValue = formatedValue.join("");
      return formatedValue;
    },
    async getCompensation() {
      return await this.methodologyController
        .getMethodologyById(this.id)
        .then((data) => {
          console.log(data);
          this.fuelCompensations = data;
          this.removeMask();
        });
    },
    async updateCompensation(data) {
      this.isLoading = true;

      this.removeMaskToSend();
      return await this.methodologyController
        .setMethodology(data)
        .then(() => {
          this.setCompensation();
        })
        .catch((error) => {
          this.isLoading = false;
          return error;
        });
    },
    removeMaskToSend() {
      this.fuelCompensations.emissionGasoline =
        this.fuelCompensations.emissionGasoline.toString().length == 3
          ? this.returnCurrency(this.fuelCompensations.emissionGasoline + "0")
          : this.returnCurrency(this.fuelCompensations.emissionGasoline);

      this.fuelCompensations.emissionDiesel =
        this.fuelCompensations.emissionDiesel.toString().length == 3
          ? this.returnCurrency(this.fuelCompensations.emissionDiesel + "0")
          : this.returnCurrency(this.fuelCompensations.emissionDiesel);
      this.fuelCompensations.emissionGasolineAdditive =
        this.fuelCompensations.emissionGasolineAdditive.toString().length == 3
          ? this.returnCurrency(
              this.fuelCompensations.emissionGasolineAdditive + "0"
            )
          : this.returnCurrency(
              this.fuelCompensations.emissionGasolineAdditive
            );
      this.fuelCompensations.emissionEthanol =
        this.fuelCompensations.emissionGasolineAdditive.toString().length == 3
          ? this.returnCurrency(this.fuelCompensations.emissionEthanol + "0")
          : this.returnCurrency(this.fuelCompensations.emissionEthanol);
    },
    removeMask() {
      this.fuelCompensations.emissionGasoline = this.formatValue(
        this.fuelCompensations.emissionGasoline
      );
      this.fuelCompensations.emissionDiesel = this.formatValue(
        this.fuelCompensations.emissionDiesel
      );
      this.fuelCompensations.emissionGasolineAdditive = this.formatValue(
        this.fuelCompensations.emissionGasolineAdditive
      );
      this.fuelCompensations.emissionEthanol = this.formatValue(
        this.fuelCompensations.emissionEthanol
      );
    },
    returnFormatNumber(number) {
      return formatReal(number);
    },
    returnCurrency(currency) {
      return removeCurrency(currency);
    },
  },
})
export default class AppMethodology extends Vue {}
