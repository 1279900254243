import { SetAddressModel } from "./AddressModel";
import { SetUserModel } from "./UserModel";

export class SetCatalogModel {
  address = new SetAddressModel();
  cnpj = null;
  fantasyName = null;
  partner = {
    id: null,
  };
  socialReason = null;
  logo = null;
}
