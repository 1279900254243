
import Loading from "vue3-loading-overlay";
import { Vue, Options } from "vue-class-component";
import UserController from "@/AppClubCarby/Controller/UserController";
import { UserInterface } from "@/models/UserModel";
import { SetCatalogModel } from "@/models/CatalogModel";
import CatalogController from "@/AppClubCarby/Controller/CatalogController";
import ViaCepService from "@/services/ViaCepService";

import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { CepIterface } from "@/models/viaCepModel";
import { cnpjWithoutMask } from "@/mixins";

import Swal from "sweetalert2";
import router from "@/router";

@Options({
  components: {
    Loading,
  },
  beforeRouteEnter(to, from, next) {
    let role = localStorage.getItem("ROLE");
    if (role === "ROLE_ADMIN") {
      next();
    } else {
      next("/not-found");
    }
  },
  created() {
    if (this.$route.params.id) {
      this.registerBool = false;
      this.getCatalogPartner(this.$route.params.id);
    }

    this.getCatalogs();
    this.getCatalogPartnerById();

    this.userController.getUsersByType("partner").then((item: any) => {
      console.log(item);
      this.users = item.content;
    });
  },

  data() {
    return {
      isLoading: false,
      catalogList: [],
      selectedCatalog: null,
      v$: useVuelidate(),
      userController: new UserController(),
      users: [],
      catalog: new SetCatalogModel(),
      catalogController: new CatalogController(),
      viaCepService: new ViaCepService(),
      cnpjWithoutMask: "",
      previewImage: "",
      registerBool: true,
    };
  },
  validations() {
    return {
      catalog: {
        socialReason: { required },
        fantasyName: { required },
        cnpj: { required, minLength: minLength(18) },
        address: {
          cep: { required, minLength: minLength(10) },
          uf: { required },
          city: { required },
          number: { required },
          street: { required },
        },
      },
    };
  },
  watch: {
    selectedCatalog: function () {
      if (this.selectedCatalog !== null) {
        this.getCatalogPartnerById(this.selectedCatalog);
      }
    },
  },
  methods: {
    register() {
      this.isLoading = true;
      this.catalog.cnpj = cnpjWithoutMask(this.catalog.cnpj);
      this.catalog.address.surname = `Endereço ${this.catalog.socialReason}`;
      this.catalogController
        ._setCatalog(this.catalog)
        .then(() => {
          this.user = new SetCatalogModel();
          this.isLoading = false;
          Swal.fire({
            title: "Yay!",
            text: "Loja cadastrada com sucesso!",
            icon: "success",
            confirmButtonText: "Ok",
          });
        })
        .catch((error: any) => {
          this.isLoading = false;
          Swal.fire({
            title: "Oops!",
            text: "Algum erro inesperado aconteceu!",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    },
    searchCep() {
      this.v$.catalog.address.cep.$touch();
      if (this.v$.catalog.address.cep.$error) {
        return;
      } else {
        const cepElement = document.getElementById("cep");
        let receivedCep = cepElement?.dataset.maskRawValue;

        this.viaCepService._getCep(receivedCep).then((item: CepIterface) => {
          this.catalog.address.street = item.data.street;
          this.catalog.address.city = item.data.city;
          this.catalog.address.uf = item.data.state;
          this.catalog.address.latitude =
            item.data.location.coordinates.latitude;
          this.catalog.address.longitude =
            item.data.location.coordinates.longitude;
        });

        this.v$.catalog.address.number.$touch();
      }
    },
    removeSpecialCaracters() {
      this.cnpjWithoutMask = this.catalog.cnpj.replaceAll(".", "");
      this.cnpjWithoutMask = this.cnpjWithoutMask.replace("-", "");
      this.cnpjWithoutMask = this.cnpjWithoutMask.replace("/", "");

      this.catalog.cnpj = this.cnpjWithoutMask;
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.catalog.logo = e.target.result;
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    deleteImage() {
      this.catalog.logo = null;
      this.previewImage = null;
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    getCatalogPartner(id: number) {
      this.catalogController
        ._getCatalogPartnerByPartnerId(id)
        .then((catalog: any) => {
          this.catalog = catalog.data;
          this.previewImage = catalog.data.logo;
        });
    },
    edit() {
      this.isLoading = true;
      this.catalog.cnpj = this.returnCnpjWithoutMask(this.catalog.cnpj);
      this.catalogController
        ._updateCatalog(this.catalog)
        .then((success: any) => {
          this.isLoading = false;
          Swal.fire({
            title: "Yay!",
            text: "Loja atualizada com sucesso!",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              router.push("/app/edicao-lojas");
            }
          });
        })
        .catch((err: any) => {
          this.isLoading = false;
          Swal.fire({
            title: "Oops!",
            text: "Um erro inesperado aconteceu!",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    },
    returnCnpjWithoutMask(cnpj: string) {
      return cnpjWithoutMask(cnpj);
    },
    async getCatalogs() {
      this.isLoading = true;
      await this.catalogController
        ._getCatalogs()
        .then((data) => {
          this.catalogList = data.data.content;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    async getCatalogPartnerById(id: number) {
      this.isLoading = true;
      await this.catalogController
        .getCatalogPartnerById(id)
        .then((data) => {
          let storeInfo = data.data;
          console.log(storeInfo);
          this.registerBool = false;
          this.catalog.socialReason = storeInfo.socialReason;
          this.catalog.fantasyName = storeInfo.fantasyName;
          this.catalog.cnpj = storeInfo.cnpj;
          this.catalog.address.cep = storeInfo.address.cep;
          this.catalog.address.city = storeInfo.address.city;
          this.catalog.address.number = storeInfo.address.number;
          this.catalog.address.street = storeInfo.address.street;
          this.catalog.address.uf = storeInfo.address.uf;
          this.catalog.partner.id = storeInfo.partner.id;

          this.isLoading = false;
        })
        .catch(() => {
          this.registerBool = true;
          this.catalog.socialReason = null;
          this.catalog.fantasyName = null;
          this.catalog.cnpj = null;
          this.catalog.address.cep = null;
          this.catalog.address.city = null;
          this.catalog.address.number = null;
          this.catalog.address.street = null;
          this.catalog.address.uf = null;
          this.catalog.partner.id = null;
          this.isLoading = false;
        });
    },
    cleanForm() {
      this.registerBool = true;
      this.catalog.socialReason = null;
      this.catalog.fantasyName = null;
      this.catalog.cnpj = null;
      this.catalog.address.cep = null;
      this.catalog.address.city = null;
      this.catalog.address.number = null;
      this.catalog.address.street = null;
      this.catalog.address.uf = null;
      this.catalog.partner.id = null;
    },
  },
})
export default class CatalogRegister extends Vue {}
