
import loading from 'vue3-loading-overlay';
import { Vue, Options } from 'vue-class-component';
import CardComponent from '@/AppClubCarby/Components/CardComponent/CardComponent.vue';
import { ClientDashboardController } from '@/AppClubCarby/Controller/DashBoardsController';
import UserController from '@/AppClubCarby/Controller/UserController';
import { UserInterface } from '@/models/UserModel';
import { getFuel, formatCurrency, getMonth } from '@/mixins';

@Options({
  beforeRouteEnter(to, from, next) {
    let role = localStorage.getItem('ROLE');
    if (role === 'ROLE_CLIENT') {
      next();
    } else {
      next('/not-found');
    }
  },
  created() {
    this.userController.getOneUserByEmail().then((res) => {
      this.user = res;
      this.setYear();
      this.getValueByYear();
    });
  },
  components: {
    CardComponent,
    loading,
  },
  data() {
    return {
      isLoading: true,
      years: [],
      selectedYear: null,
      months: [],
      seriesToChange: [],
      options: {
        legend: {
          position: 'top',
          horizontalAlign: 'right',
        },
        title: {
          text: 'Gráfico de consumo de combustíveis',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        chart: {
          id: 'vuechart-example',
          type: 'bar',
          stacked: true,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Litros',
          },
        },
        dataLabels: {
          style: {
            colors: ["#FFF", "#FFF", "#FFF", "#FFF"],
            fontSize: "10px",
          },
          background: {
            enabled: true,
            foreColor: '#000',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45
            }
          },
        },
      },
      series: [
        {
          name: 'Diesel',
          data: [],
          color: '#197BBD',
        },
        {
          name: 'Gasolina',
          data: [],
          color: '#7DED84',
        },
        {
          name: 'Gasolina Aditivada',
          data: [],
          color: '#00BD0C',
        },
        {
          name: 'Etanol',
          data: [],
          color: '#11592E',
        },
      ],
      tableData: [],
      clientDashboardController: new ClientDashboardController(),
      userController: new UserController(),
      user: [],
    };
  },
  watch: {
    seriesToChange(value, oldValue) {
      if (value) {
        this.updateChart();
      }
    },
    selectedYear(value) {
      if (value) {
        this.getValueByYear();
      }
    },
  },
  methods: {
    getFuelName(fuel: string) {
      return getFuel(fuel);
    },
    formatMoney(money: number) {
      return formatCurrency(money);
    },
    getMonthName(monthNumber: number) {
      return getMonth('short', monthNumber);
    },
    updateChart() {
      let series: any = [];
      let gasoline: any = [];
      let additiveGasoline: any = [];
      let ethanol: any = [];
      let diesel: any = [];

      let months: any = [];

      this.seriesToChange.forEach((e: any) => {
        if (this.getFuelName(e.fuelType) === 'Gasolina') {
          gasoline.push(e.filledVolume);
        }

        if (this.getFuelName(e.fuelType) === 'Gasolina Aditivada') {
          additiveGasoline.push(e.filledVolume);
        }

        if (this.getFuelName(e.fuelType) === 'Etanol') {
          ethanol.push(e.filledVolume);
        }

        if (this.getFuelName(e.fuelType) === 'Diesel') {
          diesel.push(e.filledVolume);
        }

        months.push(e.yearMonth[1]);

        months = months.filter((e: any, i: number) => {
          return months.indexOf(e) === i;
        });

        series = months.map((m: any) => {
          return this.getMonthName(m);
        });
      });

      this.options = {
        xaxis: {
          categories: series,
        },
      };

      this.series = [
        {
          name: 'Diesel',
          data: diesel,
          color: '#197BBD',
        },
        {
          name: 'Gasolina',
          data: gasoline,
          color: '#7DED84',
        },
        {
          name: 'Gasolina Aditivada',
          data: additiveGasoline,
          color: '#00BD0C',
        },
        {
          name: 'Etanol',
          data: ethanol,
          color: '#11592E',
        },
      ];
    },
    setYear() {
      let date = new Date();
      let dateArr = date.toString().split(' ');
      let year = dateArr[3];
      let actualYearNumber = parseInt(year);
      this.selectedYear = actualYearNumber;

      for (let i = 2022; actualYearNumber >= i; i++) {
        this.years.push(i);
      }
    },
    async getValueByYear() {
      this.isLoading = true;
      await this.clientDashboardController
        ._getFuelTransactionDetailed(this.user.id, this.selectedYear)
        .then((e: any) => {
          this.tableData = e.data;
        })
        .catch(() => {
          this.isLoading = false;
        });

      await this.clientDashboardController
        ._getFuelTransactionByMonth(this.user.id, this.selectedYear)
        .then((e: any) => {
          this.seriesToChange = e.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
})
export default class MyConsumption extends Vue {}
