
import { Options, Vue } from 'vue-class-component';
import CardComponent from '@/AppClubCarby/Components/CardComponent/CardComponent.vue';

@Options({
  components: {
    CardComponent,
  },
})
export default class AppTraining extends Vue {}
