
import { Vue, Options } from "vue-class-component";
import UserController from "@/AppClubCarby/Controller/UserController";
import Swal from "sweetalert2";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import { GasStationInterface } from "@/models/GasStationModel";
import Loading from "vue3-loading-overlay";
import ViaCepService from "@/services/ViaCepService";
import NameInputComponent from "@/AppClubCarby/Components/NameInputComponent/NameInputComponent.vue";

@Options({
  data() {
    return {
      userController: new UserController(),
      viaCepService: new ViaCepService(),
      gasStationController: new GasStationController(),
      selectedGasStation: {
        id: null,
      },
      gasStations: [],
      isLoading: false,
      passwordVerify: null,
      userData: {
        id: null,
        user: {
          type: "PARTNER",
          id: null,
          birthDate: null,
          cpf: null,
          email: null,
          gender: null,
          name: null,
          nationality: null,
          password: null,
          phone: null,
          address: {
            cep: null,
            city: null,
            country: null,
            district: null,
            latitude: null,
            longitude: null,
            number: null,
            street: null,
            surname: null,
            uf: null,
            complement: null,
          },
        },
        gasStation: {
          id: null,
        },
      },
    };
  },
  components: {
    Loading,
    NameInputComponent,
  },
  created() {
    this.getGasStations();
  },
  watch: {
    "userData.user.address.cep": function () {
      if (this.userData.user.address.cep.length >= 8) {
        this.selectCep();
      } else {
        return;
      }
    },
  },
  methods: {
    getGasStations() {
      this.gasStationController
        ._getGasStation()
        .then((item: GasStationInterface) => {
          this.gasStations = item.data.content;
        });
    },
    selectGasStation(e: any) {
      this.gasStations.forEach((gasStation: any) => {
        if (gasStation.gasStationId == e.target.value) {
          this.selectedGasStation.id = gasStation.gasStationId;
          console.log(this.selectedGasStation);
        }
      });
    },
    selectCep() {
      if (this.userData.user.address.cep.length == 8) {
        this.isLoading = true;
        this.viaCepService
          ._getCep(this.userData.user.address.cep)
          .then((address: any) => {
            this.userData.user.address.city = address.data.city;
            this.userData.user.address.uf = address.data.state;
            this.userData.user.address.street = address.data.street;
            this.userData.user.address.district = address.data.neighborhood;
            this.userData.user.address.surname = `Endereço ${this.userData.user.name}`;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            Swal.fire({
              title: "Ooops!",
              text: "CEP não encontrado, favor digitar novamente.",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    },
    sendForm() {
      if (this.passwordVerify !== this.userData.user.password) {
        Swal.fire({
          title: "Oops!",
          text: "As senhas não coincidem, por favor verifique!",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return false;
      }

      this.userData.gasStation = this.selectedGasStation;
      this.isLoading = true;

      this.userController
        .setUser(this.userData.user.type, this.userData)
        .then(() => {
          this.isLoading = false;
          Swal.fire({
            title: "Yay!",
            text: "Usuário cadastrado com sucesso!",
            icon: "success",
            confirmButtonText: "Ok",
          });
          this.userData.user.name = null;
          this.userData.user.cpf = null;
          this.userData.user.address.cep = null;
          this.userData.user.address.street = null;
          this.userData.user.address.complement = null;
          this.userData.user.address.district = null;
          this.userData.user.address.city = null;
          this.userData.user.address.uf = null;
          this.userData.user.address.number = null;
          this.userData.user.email = null;
          this.userData.user.password = null;
          this.passwordVerify = null;
        })
        .catch((error) => {
          this.isLoading = false;

          Swal.fire({
            title: "Oops!",
            text: "Algum erro inesperado aconteceu, verifique todos os campos e tente novamente.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    },
  },
})
export default class AppUsersPartnerRegister extends Vue {}
