
import { Options, Vue } from 'vue-class-component';
import { getInitialsFromName } from '@/mixins';
import UserController from '@/AppClubCarby/Controller/UserController';
import { mapGetters } from 'vuex';

@Options({
  data() {
    return {
      name: '',
      userService: new UserController(),
      userData: {},
      dropdown: false,
      dropdownArrow: false,
    };
  },
  created() {
    this.getUser();
  },
  computed: {
    userName: function () {
      return this.$store.state.userName;
    },
    ...mapGetters({
      managerGasStationId: 'getManagerGasStationId',
    }),
  },
  methods: {
    async getUser() {
      let userId = 0;
      await this.userService.getOneUserByEmail().then((data) => {
        this.userData = data;
        userId = data.id;
        if (data.gasStations) {
          this.$store.commit('setManagerGasStation', data.gasStations[0].id);
        }

        this.userData.user.profile == 'MANAGER'
          ? (this.dropdownArrow = true)
          : (this.dropdownArrow = false);
      });

      await this.$store.commit('setId', userId);
    },
    changeProfile(id: number) {
      this.$store.commit('setManagerGasStation', id);
      this.dropdown = false;
    },
    returnInitials(name: string) {
      return getInitialsFromName(name);
    },
    returnUserName(userName: string) {
      const name = userName.split(' ');
      const lastName = name.slice(-1);
      return name[0] + ' ' + lastName;
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown;
    },
  },
})
export default class ProfileComponent extends Vue {}
