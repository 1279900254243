
import { Options, Vue } from "vue-class-component";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import IBGEService from "@/services/IBGEService";

@Options({
  emits: ["close"],
  mounted() {
    this.ibgeService._getStates().then((states: any) => {
      this.states = states.data;
    });
  },
  data() {
    return {
      ibgeService: new IBGEService(),
      v$: useVuelidate(),
      message: {
        name: "",
        email: "",
        category: "",
        description: "",
        uf: "",
        city: "",
      },
      ufs: [],
      cities: [],
      states: [],
    };
  },
  validations() {
    return {
      message: {
        name: { required },
        email: { required, email },
        category: { required },
        description: { required },
      },
    };
  },
  methods: {
    sendMessage() {
      const API_URL = process.env.VUE_APP_API_URL;
      this.v$.$touch();

      if (this.v$.message.$error) {
        Swal.fire({
          title: "Oops!",
          text: "Preencha corretamente todos os campos e tente novamente!",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return;
      } else {
        this.modalActive = !this.modalActive;
        axios
          .post(`${API_URL}/doubt`, this.message)
          .then(() => {
            Swal.fire({
              title: "Yay!",
              text: "Mensagem enviada com sucesso! Logo entraremos em contato!",
              icon: "success",
              confirmButtonText: "Ok",
            });

            this.message = {
              name: "",
              email: "",
              category: "",
              description: "",
              uf: "",
              city: "",
            };

            this.v$.$reset();
          })
          .catch((error: any) => {
            Swal.fire({
              title: "Oops!",
              text: "Algum erro inesperado aconteceu!",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    },
    setCity() {
      if (!this.message.uf) {
        return;
      }

      this.ibgeService._getCities(this.message.uf).then((cities: any) => {
        this.cities = cities.data;
      });
    },
    emitCustomEvent() {
      this.$emit("close", false);
    },
  },
})
export default class ContactUsLandingPage extends Vue {}
