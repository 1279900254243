
import { Vue, Options } from "vue-class-component";
import MonthlyParameters from "@/AppClubCarby/View/App/Admin/AppMonthlyPayment/AppMonthlyParameters.vue";
import MonthlyHistoric from "@/AppClubCarby/View/App/Admin/AppMonthlyPayment/AppMonthlyHistoric.vue";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import { GasStationInterface } from "@/models/GasStationModel";
import { computed } from "vue";

@Options({
  data() {
    return {
      componentUsed: "MonthlyParameters",
      gasStationController: new GasStationController(),
      gasStations: [],
      selectedGasStationId: 2,
      selectedGasStation: null,
    };
  },
  provide() {
    return {
      monthlyGasStationId: computed(() => this.selectedGasStationId),
    };
  },
  components: {
    MonthlyParameters,
    MonthlyHistoric,
  },
  computed: {
    dynamicComponent() {
      return this.componentUsed;
    },
  },
  created() {
    this.getGasStations();
  },
  methods: {
    chooseComponent(viewName) {
      this.componentUsed = `${viewName}`;
    },
    getGasStations() {
      this.gasStationController
        ._getGasStation()
        .then((item: GasStationInterface) => {
          this.gasStations = item.data.content;
          console.log(this.gasStations);
        });
    },
    async selectGasStation() {
      await this.gasStationController
        .getGasStationById(this.selectedGasStationId)
        .then((data) => {
          this.selectedGasStation = data.data;
          console.log(this.selectedGasStationId);
        });
    },
  },
})
export default class AppMonthlyPayment extends Vue {}
