
import Loading from "vue3-loading-overlay";
import { Vue, Options } from "vue-class-component";
import ClientPurchaseController from "@/AppClubCarby/Controller/ClientPurchaseController";
import UserController from "@/AppClubCarby/Controller/UserController";
import Swal from "sweetalert2";
import {
  formatFuelToSend,
  getFuel,
  formatCurrencyWithoutSymbol,
} from "@/mixins/index";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import { removeCurrency } from "@/mixins";

@Options({
  components: {
    Loading,
  },
  created() {
    /*     this.fuelTransactionEdit =
      this.clientPurchaseController.getFuelTransactionById(
        this.$route.params.id
      ); */
    this.getFuelTransaction(this.$route.params.id);
  },
  data() {
    return {
      clientPurchaseController: new ClientPurchaseController(),
      gasStationController: new GasStationController(),
      userController: new UserController(),
      isLoading: true,
      gasStation: {},
      fuels: [
        {
          name: "Gasolina",
          value: "GASOLINE",
        },
        {
          name: "Gasolina Aditivada",
          value: "GASOLINE_ADDITIVE",
        },
        {
          name: "Etanol",
          value: "ETHANOL",
        },
        {
          name: "Etanol Aditivado",
          value: "ETHANOL_ADDITIVE",
        },
        {
          name: "Diesel",
          value: "DIESEL",
        },
      ],
      user: {},
      fuelTransactionEdit: {
        amount: null,
        amountCashback: null,
        amountPay: null,
        createdAt: null,
        id: null,
        fuelType: null,
        fuelPrice: null,
        filledVolume: null,
        generatedCashback: null,
        emittedCarbon: null,
        carbonOffsetAutomatically: null,
        remainingEmissions: null,
        paymentMethod: null,
        client: {
          id: null,
        },
        attendant: {
          id: null,
        },
        gasStation: {
          id: null,
        },
      },
    };
  },
  methods: {
    async getFuelTransaction(id) {
      this.isLoading = true;

      await this.clientPurchaseController
        .getFuelTransactionById(id)
        .then((data) => {
          let fuelTransaction = data.data;
          this.fuelTransactionEdit.createdAt = fuelTransaction.createdAt;
          this.fuelTransactionEdit.amount = fuelTransaction.amount;
          this.fuelTransactionEdit.amountCashback =
            fuelTransaction.amountCashback;
          this.fuelTransactionEdit.amountPay = fuelTransaction.amountPay;
          this.fuelTransactionEdit.id = fuelTransaction.id;
          this.fuelTransactionEdit.fuelType = fuelTransaction.fuelType;
          this.fuelTransactionEdit.fuelPrice = fuelTransaction.fuelPrice;
          this.fuelTransactionEdit.filledVolume = fuelTransaction.filledVolume;
          this.fuelTransactionEdit.generatedCashback =
            fuelTransaction.generatedCashback;
          this.fuelTransactionEdit.emittedCarbon =
            fuelTransaction.emittedCarbon;
          this.fuelTransactionEdit.carbonOffsetAutomatically =
            fuelTransaction.carbonOffsetAutomatically;
          this.fuelTransactionEdit.remainingEmissions =
            fuelTransaction.remainingEmissions;
          this.fuelTransactionEdit.paymentMethod =
            fuelTransaction.paymentMethod;
          this.fuelTransactionEdit.client.id = fuelTransaction.clientId;
          this.fuelTransactionEdit.gasStation.id = fuelTransaction.gasStationId;
          this.fuelTransactionEdit.attendant.id = fuelTransaction.attendantId;

          this.fuelTransactionEdit.generatedCashback = this.formatCashback(
            this.fuelTransactionEdit.generatedCashback
          );

          this.fuelTransactionEdit.amountCashback = this.formatCashback(
            this.fuelTransactionEdit.amountCashback
          );

          this.getGasStationById(fuelTransaction.gasStationId);
          this.getUser(fuelTransaction.clientId);
        });
    },

    async getGasStationById(id) {
      await this.gasStationController.getGasStationById(id).then((data) => {
        this.gasStation = data.data;

        this.isLoading = false;
      });
    },

    async getUser(clientId) {
      await this.userController
        .getOneUserById("CLIENT", clientId)
        .then((data) => {
          this.user = data.user;
        });
    },

    formatFuel(fuel) {
      return getFuel(fuel);
    },

    formatCashback(cashback) {
      if (cashback === null) {
        return "0,00";
      } else {
        return this.returnCurrency(cashback);
      }
    },

    formatFuelToSend(fuel) {
      return formatFuelToSend(fuel);
    },
    /*  async getUser() {
          let user = await this.userController.getOneUserByCPF(
        'CLIENT',
        this.$route.params.cpf
      );
      this.fuelTransactionEdit.user = user.user;

      this.getFuelTransaction(this.$route.params.id);
    }, */
    updateFuelTransaction(id, data) {
      this.isLoading = true;
      this.fuelTransactionEdit.fuelType = this.formatFuelToSend(
        this.fuelTransactionEdit.fuelType
      );
      this.fuelTransactionEdit.amountCashback = this.formatNumber(
        this.fuelTransactionEdit.amountCashback
      );
      this.fuelTransactionEdit.generatedCashback = this.formatNumber(
        this.fuelTransactionEdit.generatedCashback
      );
      this.fuelTransactionEdit.amount = this.formatNumber(
        this.fuelTransactionEdit.amount
      );

      id = this.$route.params.id;
      data = this.fuelTransactionEdit;
      if (data) {
        this.clientPurchaseController
          .editFuelTransaction(id, data)
          .then(() => {
            Swal.fire({
              title: "Yay!",
              text: "Abastecimento editado com sucesso!",
              icon: "success",
              confirmButtonText: "Ok",
            });
            this.getFuelTransaction(this.$route.params.id);
          })
          .catch((error: any) => {
            this.isLoading = false;
            Swal.fire({
              title: "Oops!",
              text: "Ocorreu algum erro! Tente novamente mais tarde",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    },
    formatNumber(number) {
      return parseFloat(removeCurrency(number));
    },
    returnCurrency(number) {
      return formatCurrencyWithoutSymbol(number);
    },
  },
})
export default class EditFuelTransaction extends Vue {}
