import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export class ManagerDashboardModel {
  constructor(managerId, startDate, endDate, gasStationId) {
    this.managerId = managerId;
    this.startDate = startDate;
    this.endDate = endDate;
    this.gasStationId = gasStationId;
  }

  managerId: number;
  filter: string;
  startDate;
  endDate;
  gasStationId: number;

  get getCards() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/cards?gas_station_id=${this.gasStationId}&start_date=${this.startDate}&end_date=${this.endDate}`
    );
  }

  get getFuelLiters() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/fuel-liters?gas_station_id=${this.gasStationId}&start_date=${this.startDate}&end_date=${this.endDate}`
    );
  }
  /* esse foi */
  get getRevenue() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/receita?gas_station_id=${this.gasStationId}&start_date=${this.startDate}&end_date=${this.endDate}`
    );
  }
  /* esse foi */
  get getCosts() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/gas_station/${this.gasStationId}/monthlyFee?startDate=${this.startDate}&endDate=${this.endDate}`
    );
  }

  get getEvolution() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/evolution?gas_station_id=${this.gasStationId}&start_date=${this.startDate}&end_date=${this.endDate}`
    );
  }

  get getFinancialAnalysis() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/finantial-analysis?gas_station_id=${this.gasStationId}&start_date=${this.startDate}&end_date=${this.endDate}`
    );
  }

  public getPaymentMethods(gasStationId: number) {
    return axios.get(
      `${API_URL}/dashboard/gas-station/payment-method?gas_station_id=${gasStationId}&start_date=${this.startDate}&end_date=${this.endDate}`
    );
  }

  public getCardDetailsGalonage() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/galonage-detailed?gas_station_id=${this.gasStationId}&start_date=${this.startDate}&end_date=${this.endDate}`
    );
  }

  public getCardDetailsRegistration() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/registration-detailed?gas_station_id=${this.gasStationId}&start_date=${this.startDate}&end_date=${this.endDate}`
    );
  }

  public getCardDetailsFidelization() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/fidelizated-clients-detailed?gas_station_id=${this.gasStationId}&start_date=${this.startDate}&end_date=${this.endDate}`
    );
  }

  public getClientEvolutionChart() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/evolution/client-registration?gas_station_id=${this.gasStationId}&start_date=${this.startDate}&end_date=${this.endDate} `
    );
  }

  public getFuelTransactionEvolutionChart() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/evolution/fuel-transaction-registration?gas_station_id=${this.gasStationId}&start_date=${this.startDate}&end_date=${this.endDate}`
    );
  }

  public getFidelizationData() {
    return axios.get(
      `${API_URL}/user/manager/dashboard/fidelizated-clients-detailed?gas_station_id=${this.gasStationId}`
    );
  }
}
