
import { Vue, Options } from 'vue-class-component';
import ClientPurchaseController from '@/AppClubCarby/Controller/ClientPurchaseController';
import { UserInterface } from '@/AppClubCarby/Model/Interfaces/UserInterfaces';
import UserController from '@/AppClubCarby/Controller/UserController';
import {
  cpfRemoveMask,
  cpfMask,
  getFuel,
  getPaymentMethod,
  formatReal,
  formatCurrencyWithoutSymbol,
  removeCurrency,
  formatCurrency,
} from '@/mixins';
import ModalComponent from '@/LandingPage/Components/ModalComponent/ModalComponent.vue';

import CardComponent from '@/AppClubCarby/Components/CardComponent/CardComponent.vue';

import CurrencyInput from '@/AppClubCarby/Components/CurrencyInput/CurrencyInput.vue';
import Swal from 'sweetalert2';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import GasStationController from '@/AppClubCarby/Controller/GasStationController';
import { ClientDashboardController } from '@/AppClubCarby/Controller/DashBoardsController';

let typingTimer: any;

@Options({
  beforeRouteEnter(to, from, next) {
    let role = localStorage.getItem('ROLE');
    if (role === 'ROLE_ATTENDANT') {
      next();
    } else {
      next('/not-found');
    }
  },
  created() {
    this.getAttendantAndGasStation();
  },
  components: {
    CurrencyInput,
    ModalComponent,
    CardComponent,
    Loading,
  },
  data() {
    return {
      searchingClientCpf: false,
      isLoading: false,
      cashbackLimit: 5,
      v$: useVuelidate(),
      attendantSelected: {},
      fuelDiesel: true,
      fuelEthanol: true,
      fuelEthanolAdditive: true,
      fuelGasoline: true,
      fuelGasolineAdditive: true,
      attendants: [],
      modalSelectClient: false,
      modalAttendantResume: false,
      user: '',
      clientPurchaseController: new ClientPurchaseController(),
      clientCpf: '',
      clientList: [],
      clientSelected: null,
      userController: new UserController(),
      gasStationController: new GasStationController(),
      clientDashboardService: new ClientDashboardController(),
      clientCashbackAmount: 0.0,
      cashBackAmountIsTrue: false,
      clientCpfMasked: null,
      fuelTransaction: {
        amount: null,
        amountValue: null,
        attendant: {
          id: null,
        },
        client: {
          id: null,
        },
        fuelType: null,
        gasStation: {
          id: null,
        },
        paymentMethod: null,
        amountCashback: 0,
      },
    };
  },
  validations() {
    return {
      fuelTransaction: {
        amount: { required },
        attendant: {
          id: { required },
        },
        client: {
          id: { required },
        },
        fuelType: { required },
        gasStation: {
          id: { required },
        },
        paymentMethod: { required },
      },
    };
  },
  methods: {
    setFuelTransaction() {
      this.v$.$touch();

      // this.fuelTransaction.amountCashback = this.clientCashbackAmount;

      this.setCashbackAmount();

      if (
        this.removeCurrency(this.fuelTransaction.amountCashback) >=
        this.removeCurrency(this.fuelTransaction.amount)
      ) {
        this.fuelTransaction.amountCashback = this.removeCurrency(
          this.fuelTransaction.amount
        );
      }
      this.fuelTransaction.amount = this.fuelTransaction.amount.replace(
        ',',
        '.'
      );
      this.fuelTransaction.amount = parseFloat(this.fuelTransaction.amount);
      console.log(this.fuelTransaction);
      if (this.v$.$error) {
        Swal.fire({
          title: 'Oops!',
          text: 'Por favor, preencha todos os dados corretamente!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        return;
      } else {
        Swal.fire({
          customClass: {
            actions: 'modal-actions',
            confirmButton: 'modal-btn modal-btn-confirm',
            cancelButton: 'modal-btn modal-btn-cancel',
          },
          buttonsStyling: false,
          html:
            '<div class="modal-popup">' +
            '<div class="modal-popup-header">' +
            '<div class="modal-logo"></div>' +
            '<p class="modal-title-confirmation"> Registro de Transação </p>' +
            '</div>' +
            '<div class="modal-container">' +
            '<div class="modal-container-header">' +
            '<p> Revise os detalhes da sua transação: </p>' +
            '<p> Cliente: ' +
            this.clientSelected.user.name +
            '</p>' +
            '<p> CPF: ' +
            this.returnCpf(this.clientCpf) +
            '</p>' +
            '</div>' +
            '<div class="modal-confirmation-info">' +
            '<p class="modal-bold"> Frentista: </p>' +
            '<p>' +
            this.attendantSelected.name +
            '</p>' +
            '<p class="modal-bold"> Combustível: </p>' +
            '<p>' +
            this.returnFuel(this.fuelTransaction.fuelType) +
            '</p>' +
            '<p class="modal-bold">  Forma de pagamento: </p>' +
            '<p>' +
            this.returnPaymentMethod(this.fuelTransaction.paymentMethod) +
            '</p>' +
            '<p class="modal-bold"> Valor abastecido: </p>' +
            '<p> R$ ' +
            formatCurrencyWithoutSymbol(this.fuelTransaction.amount) +
            '</p>' +
            '<p class="modal-bold"> Cashback utilizado: </p>' +
            '<p>' +
            '-' +
            this.returnCurrency(this.fuelTransaction.amountCashback) +
            '</p>' +
            '<p class="modal-bold"> Total a pagar: </p>' +
            '<p>' +
            this.returnCurrency(
              this.removeCurrency(this.fuelTransaction.amount) -
                this.fuelTransaction.amountCashback
            ) +
            '</p>' +
            '</div>' +
            '</div>' +
            '</div>',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          cancelButtonText: 'Voltar',
          cancelButtonAriaLabel: 'Voltar',
          cancelButtonColor: '#11592E',
          confirmButtonText: 'Enviar',
          confirmButtonAriaLabel: 'Enviar',
          confirmButtonColor: '#11592E',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            this.fuelTransaction.amount = this.removeCurrency(
              this.fuelTransaction.amount
            );
            this.clientPurchaseController
              ._setFuelTransaction(this.fuelTransaction)
              .then((res: any) => {
                if (res.status === 200 || res.status === 201) {
                  this.isLoading = false;
                  Swal.fire({
                    title: 'Yay!',
                    text: 'Transação realizada com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                  });

                  this.clientSelected = {
                    name: '',
                    cpf: '',
                  };

                  this.clientCpf = '';

                  this.fuelTransaction = {
                    amount: null,
                    attendant: {
                      id: 0,
                    },
                    client: {
                      id: 0,
                    },
                    fuelType: '',
                    gasStation: {
                      id: 0,
                    },
                    paymentMethod: '',
                    createdAt: '',
                    amountCashback: 0,
                  };

                  this.cashBackAmountIsTrue = false;

                  this.clientCashbackAmount = 0;

                  this.getAttendantAndGasStation();
                }
              })
              .catch((err: any) => {
                this.isLoading = false;

                this.fuelTransaction.amount = this.returnFormatMask(
                  this.fuelTransaction.amount
                );

                if (err.response.data.mensagem) {
                  Swal.fire({
                    title: 'Oops!',
                    text: err.response.data.mensagem,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                  });
                } else {
                  this.isLoading = false;
                  Swal.fire({
                    title: 'Oops!',
                    text: 'Algum erro inesperado aconteceu!',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                  });
                }
              });
          }
        });
      }
    },
    async searchClient() {
      if (this.clientCpf) {
        this.searchingClientCpf = true;
        this.isLoading = true;
        await this.clientPurchaseController
          ._findClientByCpf(cpfRemoveMask(this.clientCpf))
          .then((item: any) => {
            this.searchingClientCpf = false;
            this.isLoading = false;
            this.clientList = item.data;
            this.openModalSelectClient();
          })
          .catch((err: any) => {
            this.isLoading = false;
            this.searchingClientCpf = false;
            console.log(err);
          });

      } else {
        this.clientList = [];
      }
    },
    searchClientByCpf() {
      clearTimeout(typingTimer);
      if(this.clientCpf.length == 4){
        typingTimer = setTimeout(() => {
        this.searchClient();
        }, 1500);
      }
      else if(this.clientCpf.length == 8 || this.clientCpf.length == 12 || this.clientCpf.length == 14){
        clearTimeout(typingTimer);
        this.searchClient();
      }
    },
    selectClient(client: any) {
      this.fuelTransaction.client.id = client.id;
      this.userController
        .getOneUserByCPF('client', client.cpf)
        .then((item: any) => {
          this.clientSelected = item;
          this.clientCpf = this.clientSelected.user.cpf;
          this.getCashbackByGasStationId(
            item.id,
            this.user.gasStation.network.id
          );
          /* this.clientCashbackAmount = item.cashbackBalance; */
        });
      this.openModalSelectClient();
    },

    async getCashbackByGasStationId(userId, networkId) {
      return await this.clientDashboardService
        .getTransactionsByNetwork(userId, networkId)
        .then((data) => {
          this.clientCashbackAmount = data.data.amountCashbackValue;
        });
    },
    returnCpf(v: any) {
      this.clientCpfMasked = v;
      return cpfMask(v);
    },
    returnFuel(fuel: string) {
      return getFuel(fuel);
    },
    returnPaymentMethod(paymentMethod: string) {
      return getPaymentMethod(paymentMethod);
    },
    getAttendantAndGasStation() {
      this.userController.getOneUserByEmail().then((users: any) => {
        this.user = users;
        this.fuelTransaction.gasStation.id = this.user.gasStation.id;
        this.getFuelAvailability();
        this.userController
          .getAttendantsByGasStation(this.fuelTransaction.gasStation.id)
          .then(async (attendants: any) => {
            this.attendants = attendants;
          });
      });
    },
    async getFuelAvailability() {
      await this.gasStationController
        .getGasStationById(this.user.gasStation.id)
        .then((item) => {
          let gasoline = item.data.gasoline;
          let gasolineAdditive = item.data.gasolineAdditive;
          let ethanol = item.data.ethanol;
          let ethanolAdditive = item.data.ethanolAdditive;
          let diesel = item.data.diesel;

          gasoline === null
            ? (this.fuelGasoline = false)
            : (this.fuelGasoline = true);

          gasolineAdditive === null
            ? (this.fuelGasolineAdditive = false)
            : (this.fuelGasolineAdditive = true);

          ethanol === null
            ? (this.fuelEthanol = false)
            : (this.fuelEthanol = true);

          ethanolAdditive === null
            ? (this.fuelEthanolAdditive = false)
            : (this.fuelEthanolAdditive = true);

          diesel === null
            ? (this.fuelDiesel = false)
            : (this.fuelDiesel = true);
        });
    },

    openModalSelectClient() {
      this.modalSelectClient = !this.modalSelectClient;
    },
    openModalAttendantResume() {
      this.modalAttendantResume = !this.modalAttendantResume;
    },
    selectAttendant(e: any) {
      this.attendants.forEach((attendant: any) => {
        if (attendant.profileId == e.target.value) {
          this.attendantSelected = attendant;
          this.fuelTransaction.attendant.id = this.attendantSelected.profileId;
        }
      });
    },
    returnFormatReal(data: any) {
      const value = data.target.value.replace(',', '.');

      if (data.target.value.length >= 2) {
        this.fuelTransaction.amount = formatCurrencyWithoutSymbol(value);
      }
    },
    returnFormatMask(data: any) {
      return formatReal(data);
    },
    removeCurrency(data: any) {
      return removeCurrency(data);
    },
    returnActualDate() {
      let date = new Date();
      let month = date.getMonth() + 1;
      let finalMonth;
      if (month < 10) {
        finalMonth = `0${month}`;
      }
      let day = date.getDate();
      let finalDay;
      if (day < 10) {
        finalDay = `0${day}`;
      } else {
        finalDay = day;
      }
      let finalDate = `${finalDay}/${finalMonth}/${date.getFullYear()}`;

      return finalDate;
    },
    returnCurrency(money: number) {
      return formatCurrency(money);
    },
    setCashbackAmount() {
      this.fuelTransaction.amountCashback = this.cashBackAmountIsTrue
        ? this.clientCashbackAmount
        : 0;
    },
  },
})
export default class ClientPurchase extends Vue {}
