
import { Vue, Options } from 'vue-class-component';
import ModalComponent from '@/LandingPage/Components/ModalComponent/ModalComponent.vue';
import Swal from 'sweetalert2';
import loading from 'vue3-loading-overlay';
import CardComponent from '@/AppClubCarby/Components/CardComponent/CardComponent.vue';
import GasStationController from '@/AppClubCarby/Controller/GasStationController';

@Options({
  data() {
    return {
      editNetworkName: true,
      gasStationController: new GasStationController(),
      gasStationsNotAssociated: [],
      network: {
        name: null,
        gasStations: [],
        quantity: null,
      },
      associatedGasStations: [],
      withoutGasStations: false,
      isLoading: true,
      removeModal: false,
      gasStationToRemove: null,
      gasStationToInsert: null,
      nameToRemove: null,
    };
  },
  mounted() {
    const listElm = document.querySelector('#extractInfiniteScroll');

    let page = 0;

    listElm.addEventListener('scroll', (e) => {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        page++;

        this.updateTable(page);
      }
    });
  },
  created() {
    /* this.isLoading = true; */
    this.getGasStations();
    this.getNetworkInformations();
  },
  methods: {
    async getNetworkInformations() {
      let id = this.$route.params.id;
      let gasStations;

      if (this.$route.params.id) {
        await this.gasStationController
          .getNetworkById(id)
          .then((data) => {
            console.log(data);
            gasStations = data.data.gasStations;
            this.network = data.data;

            this.withoutGasStations = data.data.quantity == 0 ? true : false;

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
          });
      }
    },
    getGasStations() {
      this.gasStationController.gasStationsWithoutNetwork().then((item) => {
        this.gasStationsNotAssociated = item.data;
      });
    },
    toggleEdit() {
      if (this.editNetworkName == false) {
        this.gasStationController
          .editGasStationNetwork(this.$route.params.id, this.network)
          .then(() => {
            Swal.fire({
              title: 'Yay!',
              text: 'Nome da Rede alterado com sucesso!',
              icon: 'success',
              confirmButtonText: 'Ok',
            });
          })
          .catch(() => {
            Swal.fire({
              title: 'Ooops!',
              text: 'Nome da Rede não pode ser alterado. Tente novamente mais tarde!',
              icon: 'error',
              confirmButtonText: 'Ok',
            });
          });
      }
      this.editNetworkName = !this.editNetworkName;
    },
    toggleModal() {
      this.removeModal = !this.removeModal;
    },
    getIdToRemove(id, name) {
      this.gasStationToRemove = id;
      this.nameToRemove = name;
      this.toggleModal();
    },
    removeFromNetwork() {
      this.gasStationController
        .removeGasStationFromNetwork(this.gasStationToRemove)
        .then(() => {
          this.toggleModal();
          this.nameToRemove = null;
          this.getGasStations();
          this.getNetworkInformations();
          Swal.fire({
            title: 'Yay!',
            text: 'Posto removido com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
        })
        .catch((error) => {
          this.getGasStations();
          this.getNetworkInformations();
          Swal.fire({
            title: 'Ooops!',
            text: 'O posto não pode ser removido no momento, tente novamente mais tarde.!',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
        });
    },
    addGasStationToNetwork() {
      this.network.gasStations.push(this.gasStationToInsert);
      console.log(this.network);
      this.gasStationController
        .editGasStationNetwork(this.$route.params.id, this.network)
        .then((item) => {
          console.log(item);
          Swal.fire({
            title: 'Yay!',
            text: 'Posto inserido com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
        })
        .catch(() => {
          Swal.fire({
            title: 'Ooops!',
            text: 'O posto não pode ser alterado. Tente novamente mais tarde!',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        });
    },
  },
  components: {
    loading,
    CardComponent,
    ModalComponent,
  },
})
export default class AppEditNetwork extends Vue {}
