
import MenuLanding from "@/LandingPage/Components/MenuLanding/MenuLanding.vue";
import { Options, Vue } from "vue-class-component";
import UserController from "@/AppClubCarby/Controller/UserController";
import Swal from "sweetalert2";
import Footer from "@/LandingPage/Components/Footer/Footer.vue";
import ModalComponent from "@/LandingPage/Components/ModalComponent/ModalComponent.vue";

@Options({
  components: {
    MenuLanding,
    Footer,
    ModalComponent,
  },
  data() {
    return {
      userController: new UserController(),
      email: null,
      emailError: false,
      modalActive: false,
    };
  },
  methods: {
    toggleModal(){
      this.modalActive = !this.modalActive;
    },
    recoverPass() {
      if (this.email) {
        const trimEmail = this.email.trim();
        this.userController
          .recoverPassword(trimEmail)
          .then((success: any) => {
            Swal.fire({
              text: "E-mail enviado com sucesso!",
              icon: "success",
              confirmButtonText: "Ok",
            });
          })
          .catch((err: any) => {
            Swal.fire({
              title: "Oops!",
              text: "Algum erro inesperado aconteceu!",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
        this.emailError = false;
      } else {
        this.emailError = true;
      }
    },
  },
})
export default class ForgotPassword extends Vue {}
