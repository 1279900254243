
import { Vue, Options } from 'vue-class-component';
import CardComponent from '@/AppClubCarby/Components/CardComponent/CardComponent.vue';
import UserController from '@/AppClubCarby/Controller/UserController';
import { cpfMask, returnRole } from '@/mixins';
import Loading from 'vue3-loading-overlay';

@Options({
  components: {
    CardComponent,
    Loading,
  },
  created() {
    this.updatingTable = true;
    this.userController.getUsersByType('attendant').then((res) => {
      this.usersData = res.content;
      /* this.usersData = this.usersData.filter((element) => element.isActive); */
      this.updatingTable = false;
    });
  },
  mounted() {
    const listElm = document.querySelector('#extractInfiniteScroll');

    let page = 0;

    listElm.addEventListener('scroll', (e) => {
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        !this.updatingTable &&
        !this.lastPage
      ) {
        page++;

        this.updateTable(page);
      }
    });
  },
  data() {
    return {
      userController: new UserController(),
      usersData: [],
      updatingTable: false,
      lastPage: false,
      nameToSearch: '',
      searchingTable: false,
    };
  },
  methods: {
    updateTable(page) {
      this.searchingTable = true;

      this.isLoading = true;
      this.usersData = page == 0 ? [] : [...this.usersData];
      this.userController
        .getUsersByType('attendant', page)
        .then((list: any) => {
          console.log('[lista]', list);
          if (!list.empty) {
            this.updatingTable = true;
            this.lastPage = list.last;
            if (list.content.length > 0) {
              this.usersData =
                page == 0 ? list.content : [...this.usersData, ...list.content];
              this.updatingTable = false;
            } else {
              this.usersData = [];
            }
            this.isLoading = false;
            this.searchingTable = false;
            this.updatingTable = false;
          } else {
            this.searchingTable = false;
            this.updatingTable = false;
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          this.searchingTable = false;
        });
    },

    cpfMask(cpf: string) {
      return cpfMask(cpf);
    },
    returnRole(role: string) {
      return returnRole(role);
    },
    returnStatus(status: boolean) {
      return status ? 'ATIVO' : 'INATIVO';
    },
    clearName() {
      const table = document.getElementById('extractInfiniteScroll');
      this.nameToSearch = '';
      if (this.typeSearch == 'name') {
        this.updatingTable = true;
        table.scrollTo(0, 0);
        this.usersData = [];
        this.updateTable(0);
      }
    },
    searchByName(pag) {
      this.updatingTable = true;
      this.typeSearch = 'name';
      this.usersData = [];
      const table = document.getElementById('extractInfiniteScroll');
      let page = pag ? pag : 0;
      if (page == 0) {
        table.scrollTo(0, 0);
      }
      this.isLoading = true;
      this.searchingTable = true;

      this.userController
        .getUsersByType('attendant', page, this.nameToSearch)
        .then((res) => {
          this.usersData =
            page == 0 ? [...res.content] : [...this.usersData, ...res.content];
          this.lastPage = res.last;
          this.searchingTable = false;
          this.updatingTable = false;
        })
        .catch(() => {
          this.searchingTable = false;
          this.updatingTable = false;
        });
    },
    formatGasStationName(gasStationName) {
      const name = gasStationName.split('Posto ')[1];
      return name;
    },
  },
})
export default class AppUsersAttendantRegisterData extends Vue {}
