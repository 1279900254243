
import { Vue, Options } from "vue-class-component";
import CardComponent from "@/AppClubCarby/Components/CardComponent/CardComponent.vue";
import UserController from "@/AppClubCarby/Controller/UserController";
import { cpfMask, returnRole } from "@/mixins";
import Loading from "vue3-loading-overlay";

@Options({
  components: {
    CardComponent,
    Loading,
  },
  mounted() {
    const listElm = document.querySelector("#extractInfiniteScroll");

    let page = 0;

    listElm.addEventListener("scroll", (e) => {
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        !this.updatingTable &&
        !this.lastPage
      ) {
        page++;

        this.updateTable(page);
      }
    });
  },
  created() {
    this.updateTable(0);
  },
  data() {
    return {
      userController: new UserController(),
      usersData: [],
      nameToSearch: "",
      updatingTable: false,
      lastPage: false,
      searchingTable: true,
    };
  },
  methods: {
    cpfMask(cpf: string) {
      return cpfMask(cpf);
    },
    returnRole(role: string) {
      return returnRole(role);
    },
    returnStatus(status: boolean) {
      return status ? "ATIVO" : "INATIVO";
    },

    updateTable(page) {
      this.searchingTable = true;
      this.isLoading = true;
      this.usersData = page == 0 ? [] : [...this.usersData];
      this.userController.getUsersByType("manager", page).then((list) => {
        if (!list.empty) {
          this.updatingTable = true;
          this.lastPage = list.last;
          if (list.content.length > 0) {
            this.usersData =
              page == 0 ? list.content : [...this.usersData, ...list.content];
            this.updatingTable = false;
          } else {
            this.usersData = [];
          }
          this.searchingTable = false;
          this.isLoading = false;
        } else {
          this.updatingTable = false;
          this.searchingTable = false;
          return;
        }
      });
    },

    clearName() {
      const table = document.getElementById("extractInfiniteScroll");
      this.nameToSearch = "";
      if (this.typeSearch == "name") {
        table.scrollTo(0, 0);
        this.updateTable(0);
      }
    },

    searchByName(pag) {
      this.typeSearch = "name";
      const table = document.getElementById("extractInfiniteScroll");
      let page = pag ? pag : 0;
      if (page == 0) {
        table.scrollTo(0, 0);
      }
      this.isLoading = true;
      this.updatingTable = true;
      this.searchingTable = true;

      this.userController
        .getUsersByType("manager", page, this.nameToSearch)
        .then((res) => {
          this.usersData =
            page == 0 ? [...res.content] : [...this.usersData, ...res.content];
          this.lastPage = res.last;
          this.searchingTable = false;
          this.updatingTable = false;
        })
        .catch(() => {
          this.searchingTable = false;
          this.updatingTable = false;
        });
    },
  },
})
export default class AppUsersManagerRegisterData extends Vue {}
