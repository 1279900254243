import ClientPurchaseModel from "../Model/ClientPurchaseModel";
import { SetFuelTransactionInterface } from "../Model/Interfaces/FuelTransactionInterfaces";

export default class ClientPurchaseController {
  clientPurchase = new ClientPurchaseModel();

  private async _setFuelTransaction(
    fuelTransaction: SetFuelTransactionInterface
  ) {
    return await this.clientPurchase._setFuelTransaction(fuelTransaction);
  }

  public async getFuelTransactionById(id) {
    return await this.clientPurchase.getFuelTransactionById(id);
  }

  public async editFuelTransaction(id: number, data: any) {
    return await this.clientPurchase._editFuelTransactionById(id, data);
  }

  private async _findClientByCpf(cpf: string) {
    return await this.clientPurchase._findClientByCpf(cpf);
  }

  public async getCashbackAmount(clientId: number) {
    return await this.clientPurchase._getCashbackAmount(clientId);
  }

  public async getClientCashbackByGasStationId(
    clientId: number,
    gasStationId: number
  ) {
    return await this.clientPurchase.getClientCashbackByGasStationId(
      clientId,
      gasStationId
    );
  }
}
