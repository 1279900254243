
import { Vue, Options } from 'vue-class-component';
import CardComponent from '@/AppClubCarby/Components/CardComponent/CardComponent.vue';
import UserController from '@/AppClubCarby/Controller/UserController';
import { cpfMask, returnRole, formatName, formatCurrency } from '@/mixins';
import Loading from 'vue3-loading-overlay';

@Options({
  components: {
    CardComponent,
    Loading
  },
  created() {
    this.userController.getUsersByType('client', 0).then((res) => {
      this.usersData = res.content;
      this.searchingTable = false
    });
  },
  mounted() {
    const listElm = document.querySelector('#extractInfiniteScroll');

    let page = 0;
    let pageSearch = 0

    listElm.addEventListener('scroll', (e) => {
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        !this.lastPage &&
        !this.updatingTable && this.nameToSearch.length < 2
      ) {
        console.log('entrou no update')
        page++;
        console.log(page);

        this.updateTable(page);
      }



      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        !this.lastPage &&
        !this.updatingTable && this.nameToSearch.length > 2) {
        pageSearch++
        console.log('entrou no search')
        console.log(pageSearch)
        this.searchByName(pageSearch)
      }
    });
  },
  data() {
    return {
      userController: new UserController(),
      usersData: [],
      updatingTable: false,
      lastPage: false,
      nameToSearch: '',
      searchingTable: true,
      typeSearch: 'all'
    };
  },
  methods: {
    cpfMask(cpf: string) {
      return cpfMask(cpf);
    },
    returnRole(role: string) {
      return returnRole(role);
    },
    returnName(name: string) {
      return formatName(name);
    },
    updateTable(page) {
      this.typeSearch = 'all'
      this.isLoading = true;
      this.updatingTable = true;

      this.usersData = page == 0 ? [] : [...this.usersData];
      this.userController.getUsersByType('client', page).then((list: any) => {
        this.lastPage = list.last;
        if (list.content.length) {
          this.usersData =
            page == 0 ? list.content : [...this.usersData, ...list.content];
          this.updatingTable = false;
        } else {
          this.usersData = [];
        }
        this.isLoading = false;
      });
    },
    formatCashback(cashback: number) {
      if (cashback === null) {
        return '0,00';
      } else {
        return formatCurrency(cashback);
      }
    },
    returnStatus(status: boolean) {
      return status ? 'ATIVO' : 'INATIVO';
    },

    clearName() {
      const table = document.getElementById('extractInfiniteScroll')
      this.nameToSearch = ''
      if (this.typeSearch == 'name') {
        table.scrollTo(0, 0)
        this.updateTable(0)
      }
    },

    searchByName(pag) {
      this.typeSearch = 'name'
      console.log(pag)
      const table = document.getElementById('extractInfiniteScroll')
      let page = pag ? pag : 0
      if (page == 0) {
        table.scrollTo(0, 0)
      }
      this.isLoading = true;
      this.updatingTable = true;
      this.searchingTable = true

      this.userController.getUsersByType('client', page, this.nameToSearch).then((res) => {
        console.log('[res]', res)
        this.usersData = page == 0 ? [...res.content] : [...this.usersData, ...res.content]
        this.lastPage = res.last;
        this.searchingTable = false;
        this.updatingTable = false;
      }).catch(() => {
        this.searchingTable = false;
        this.updatingTable = false;
      })
    }
  },
})
export default class RegisterData extends Vue { }
