
import CardComponent from "@/AppClubCarby/Components/CardComponent/CardComponent.vue";
import PartnerExchangeController from "@/AppClubCarby/Controller/PartnerExchangeController";
import { Options, Vue } from "vue-class-component";
import UserController from "@/AppClubCarby/Controller/UserController";
import { cpfMask, formatCurrency } from "@/mixins";

@Options({
  components: {
    CardComponent,
  },
  created() {
    this.userController.getOneUserByEmail().then((res) => {
      this.getTransactions(res.id);
    });
  },
  data() {
    return {
      TransactionsRecord: [],
      filter: "",
      partnerExchangeController: new PartnerExchangeController(),
      selectedPartner: {
        user: {
          name: "",
        },
      },
      userController: new UserController(),
    };
  },

  computed: {
    searchTransaction() {
      if (this.filter) {
        let exp = new RegExp(this.filter.trim(), "i");
        return this.TransactionsRecord.filter(
          (transaction: any) =>
            exp.test(transaction.createdAt) ||
            exp.test(transaction.clientCpf) ||
            exp.test(transaction.cashback) ||
            exp.test(transaction.partnerName)
        );
      } else {
        return this.TransactionsRecord;
      }
    },
  },
  methods: {
    getTransactions(userId: number) {
      this.partnerExchangeController
        ._getCatalogPartnerByUser(userId)
        .then((el: any) => {
          this.partnerExchangeController
            .getPartnerExchangeTransactions(el.data.id)
            .then((response: any) => {
              this.TransactionsRecord = response.data;
            });
        });
    },

    returnCpfMask(cpf: string) {
      return cpfMask(cpf);
    },

    returnCurrency(currency: number) {
      return formatCurrency(currency);
    },
  },
})
export default class AppTransactionsRecord extends Vue {}
