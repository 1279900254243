
import { Vue, Options } from "vue-class-component";
import UserController from "@/AppClubCarby/Controller/UserController";
import { GetUserInterface } from "@/models/UserModel";
import ViaCepService from "@/services/ViaCepService";

import { cpfRemoveMask, phoneRemoveMask } from "@/mixins";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import NameInputComponent from "@/AppClubCarby/Components/NameInputComponent/NameInputComponent.vue";
import { ignorableKeyModifiers } from "@vue/test-utils/dist/constants/dom-events";

@Options({
  components: {
    Loading,
    NameInputComponent,
  },
  updated() {
    if (this.$route.params.id) {
      this.complete = true;
    } else {
      this.complete = false;
    }
  },
  created() {
    this.userController
      .getOneUserByEmail()
      .then(async (res) => {
        this.isLoading = true;
        this.role = localStorage.getItem("ROLE");
        this.user.id = res.id;
        this.user.attendantCode = res.attendantCode;
        this.user.user.id = res.user.id;
        this.user.user.birthDate = res.user.birthDate;
        this.user.user.name = res.user.name;
        this.user.user.cpf = res.user.cpf;
        this.user.user.gender = res.user.gender;
        this.user.user.email = res.user.email;
        this.user.user.nationality = res.user.nationality;
        this.user.user.phone = res.user.phone;
        this.user.user.address = res.user.address;

        if (this.role === "ROLE_MANAGER") {
          this.user.gasStations = res.gasStations;
        }

        if (this.role === "ROLE_ATTENDANT") {
          this.user.gasStation = res.gasStation;
        }
        if (this.role === "ROLE_CLIENT") {
          this.user.favoriteGasStations = res.favoriteGasStations.map(
            (elem) => ({
              id: elem.id,
            })
          );
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  data() {
    return {
      v$: useVuelidate(),
      isLoading: true,
      updateLoading: false,
      passwordConfirm: false,
      userController: new UserController(),
      viaCepService: new ViaCepService(),
      user: {
        id: null,
        attendantCode: null,
        user: {
          id: null,
          birthDate: null,
          cpf: null,
          email: null,
          gender: null,
          name: null,
          nationality: null,
          password: null,
          phone: null,
          address: {
            cep: null,
            city: null,
            country: null,
            district: null,
            latitude: null,
            longitude: null,
            number: null,
            street: null,
            surname: null,
            uf: null,
            complement: null,
          },
        },
      },
      complete: false,
      role: null,
      password: null,
    };
  },
  validations() {
    return {
      user: {
        user: {
          name: { required },
          email: { required, email },
          cpf: { required, minLength: minLength(14) },
        },
      },
    };
  },

  methods: {
    onSubmit() {
      if (this.password && this.user.user.password) this.verifyPassword();
      else {
        this.user.user.password = null;
        this.updateUser();
      }
    },
    verifyPhone() {
      if (
        this.user.user.phone.length > 12 &&
        this.user.user.phone.length < 15
      ) {
        this.phoneError = false;
      } else if (this.user.user.phone.length == 0) {
        this.phoneError = false;
      } else {
        this.phoneError = true;
      }
    },
    completeRegister() {
      this.$router.push(`/app/complete-profile/${this.user.id}`);
    },
    updateUser() {
      this.v$.$validate();
      this.updateLoading = true;
      const userType = this.$store.state.decodedToken.roles[0].replace(
        "ROLE_",
        ""
      );

      if (!this.v$.$error) {
        if (this.user["user"].cpf) {
          this.user["user"].cpf = cpfRemoveMask(this.user["user"].cpf);
        }
        if (this.user["user"].phone && this.user["user"].phone.length === 18) {
          this.user["user"].phone = phoneRemoveMask(this.user["user"].phone);
        } else if (this.user["user"].phone === "") {
          this.user["user"].phone = null;
        }

        this.userController
          .editUser(userType.toLowerCase(), this.user)
          .then((res: any) => {
            if (res.status === 200 || res.status === 201) {
              Swal.fire({
                title: "Yay!",
                text: "Usuário atualizado com sucesso!",
                icon: "success",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((err) => {
            if (err.response.data.mensagem) {
              Swal.fire({
                title: "Oops!",
                text: err.response.data.mensagem,
                icon: "error",
                confirmButtonText: "Ok",
              });
            } else {
              const errorMessages = Object.values(err.response.data.erros).join(
                " "
              );
              Swal.fire({
                title: "Oops!",
                text: errorMessages,
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          })
          .finally(() => {
            this.updateLoading = false;
          });
      } else {
        this.updateLoading = false;
        Swal.fire({
          title: "Oops!",
          text: "Algum erro inesperado aconteceu!",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    selectCep() {
      this.viaCepService
        ._getCep(this.user.user.address.cep)
        .then((address: any) => {
          this.user.user.address.city = address.data.city;
          this.user.user.address.uf = address.data.state;
          this.user.user.address.street = address.data.street;
          this.user.user.address.district = address.data.neighborhood;
        });
    },
    verifyPassword() {
      this.updateLoading = true;
      if (this.password.length >= 6) {
        this.userController
          .comparePassword(this.user.user.id, { password: this.password })
          .then((item: any) => {
            if (item.data) {
              if (
                this.user.user.password.length >= 6 &&
                this.user.user.password.length <= 20
              )
                this.updateUser();
              else {
                Swal.fire({
                  title: "Oops!",
                  text: "A nova senha deve ter entre 6 e 20 caracteres!",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            } else {
              Swal.fire({
                title: "Oops!",
                text: "Senha atual incorreta",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error: any) => {
            Swal.fire({
              title: "Oops!",
              text: "Senha atual incorreta",
              icon: "error",
              confirmButtonText: "Ok",
            });
          })
          .finally(() => {
            this.updateLoading = false;
          });
      } else {
        this.updateLoading = false;
        Swal.fire({
          title: "Oops!",
          text: "A senha deve ter entre 6 e 20 caracteres!",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
  },
})
export default class AppProfile extends Vue {}
