
import { Vue, Options } from 'vue-class-component';

@Options({
  props: ['title', 'center', 'scroll', 'middle'],
  emits: ['close'],
  data() {
    return {};
  },
  methods: {
    emitCustomEvent() {
      this.$emit('close', false);
    },
  },
})
export default class ModalComponent extends Vue { }
