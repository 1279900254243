
import { Options, Vue } from "vue-class-component";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import CardComponent from "@/AppClubCarby/Components/CardComponent/CardComponent.vue";
import { getInitialsFromName } from "@/mixins";

@Options({
  components: {
    CardComponent,
  },
  created() {
    this.getGasStations();
  },
  data() {
    return {
      GasStationsListStore: [],
      filter: "",
      gasStationController: new GasStationController(),
    };
  },
  computed: {
    searchStore() {
      if (this.filter) {
        let exp = new RegExp(this.filter.trim(), "i");
        return this.GasStationsListStore.filter(
          (store: any) => exp.test(store.Name) || exp.test(store.Address)
        );
      } else {
        return this.GasStationsListStore;
      }
    },
  },
  methods: {
    getGasStations() {
      this.gasStationController._getGasStation().then((gasStation: any) => {
        this.GasStationsListStore = gasStation.data.content.map((item: any) => {
          console.log(item);
          return {
            Name: item.fantasyName,
            Address: `${item.address.street} n. ${item.address.number}, ${item.address.city}-${item.address.uf}`,
          };
        });
      });
    },
    returnInitials(fantasyName: string) {
      return getInitialsFromName(fantasyName);
    },
  },
})
export default class AppGasStationsList extends Vue {}
