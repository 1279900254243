<template>
  <loading
    :active="isLoading"
    :can-cancel="false"
    :is-full-page="true"
  ></loading>
  <div v-if="id">
    <div class="board">
      <p>Preço de Uso da Plataforma</p>
      <div>
        R$
        <span v-if="!error">
          {{ formatValue(parameters.priceUsePlatform) }}</span
        >
        <span v-else> {{ parameters.priceUsePlatform }}</span>
      </div>
    </div>
    <div class="board">
      <p>Margem Carby-Cashback</p>
      <div>
        <span v-if="!error" class="cashback">
          {{ formatValue(parameters.carbyCashbackMargin) }}</span
        >
        <span v-else class="cashback">
          {{ parameters.carbyCashbackMargin }}</span
        >
        %
      </div>
    </div>
    <div class="board">
      <p>Preço de Venda do Carbono</p>
      <div>
        R$
        <span v-if="!error">
          {{ formatValue(parameters.carbonSalePriceTotalInKg) }}</span
        >
        <span v-else> {{ parameters.carbonSalePriceTotalInKg }}</span> /kg
      </div>
    </div>
  </div>
</template>

<script>
import loading from "vue3-loading-overlay";
import { Vue, Options } from "vue-class-component";
import MonthlyPaymentController from "@/AppClubCarby/Controller/MonthlyPaymentController";
import Swal from "sweetalert2";
import {
  formatCurrency,
  formatCurrencyWithoutSymbol,
  removeCurrency,
  formatNumber,
  formatReal,
} from "@/mixins";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import CarbonController from "@/AppClubCarby/Controller/CarbonController";

@Options({
  data() {
    return {
      monthlyPaymentController: new MonthlyPaymentController(),
      gasStationController: new GasStationController(),
      error: false,
      parameters: {},
      isLoading: false,
      gasStationId: null,
    };
  },
  inject: {
    id: {
      from: "monthlyGasStationId",
    },
  },
  created() {
    this.getMonthlyPayment();
  },
  components: {
    loading,
  },
  watch: {
    "id.value": function () {
      this.getMonthlyPayment();
    },
  },
  methods: {
    async getMonthlyPayment() {
      this.loading = true;
      await this.monthlyPaymentController
        .getParametersByGasStationId(this.id.value)
        .then((data) => {
          this.error = false;
          this.parameters = data.data;

          this.loading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.error = true;
          this.parameters.platformPrice = "Erro";
          this.parameters.cashbackPrice = "Erro";
          this.parameters.compensationPrice = "Erro";
          Swal.fire({
            title: "Ooops!",
            text: "Ocorreu algum problema para encontrar o registro do posto. Tente novamente mais tarde.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    },
    formatValue(value) {
      if (value === 0) {
        return "0,00";
      } else {
        return formatCurrencyWithoutSymbol(value);
      }
    },

    returnFormatNumber(number) {
      return formatReal(number);
    },

    returnCurrency(currency) {
      return removeCurrency(currency);
    },
  },
})
export default class AppMonthlyParameters extends Vue {}
</script>

<style lang="scss" scoped>
@import "./AppMonthlyPayment.scss";
</style>
