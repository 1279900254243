import { getCookie, removeCookie, setCookie } from "@/mixins";
import router from "@/router";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { createStore } from "vuex";

const API_URL = process.env.VUE_APP_API_URL;

const store = createStore({
  state: {
    status: "",
    token: getCookie("AUTHORIZATION") || "",
    decodedToken: { sub: "", roles: [], exp: 0 },
    ecopoints: 0,
    userName: "",
    userEmail: "",
    userId: 0,
    validEmail: null,
    isCompleted: false,
    gasStationName: "",
    loggedIn: true,
    managerGasStationId: null,
    Attendant: { id: null, edit: false}
  },
  mutations: {
    setManagerGasStation(state, newValue) {
      state.managerGasStationId = newValue;
    },
    setEditAttendant(state, newValue) {
      state.Attendant = newValue;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      removeCookie("keepLoggedIn");
      removeCookie("AUTHORIZATION");
      state.status = "";
      (state.token = ""), (state.decodedToken = { sub: "", roles: [], exp: 0 });
    },
    decodeToken(state) {
      const splited = state.token.split("Bearer ");
      state.decodedToken = jwt_decode(splited[1]);
      localStorage.setItem("ROLE", state.decodedToken.roles[0]);
    },
    setName(state, name) {
      state.userName = name;
    },
    setId(state, id) {
      state.userId = id;
    },
    setValidEmail(state, validate) {
      state.validEmail = validate;
    },
    setUserEmail(state, email) {
      state.userEmail = email;
    },
    setEcopoints(state, totalEcopoints) {
      state.ecopoints = totalEcopoints;
    },
    setIsCompleted(state, isCompleted) {
      state.isCompleted = isCompleted;
    },
    updateToken(state, token) {
      state.token = token;
    },
    keepLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
  },
  actions: {
    setManagerGasStation({ commit }, value) {
      commit("setManagerGasStation", value);
    },
    setEditAttendant({ commit }, value) {
      commit("setEditAttendant", value);
    },
    login({ commit }, data) {
      commit("auth_success", data.token);
      this.dispatch("decodeToken");

      if (data.keepLoggedIn) {
        this.dispatch("keepLoggedIn", data.keepLoggedIn);
        setCookie(
          "AUTHORIZATION",
          data.token,
          this.state.decodedToken.exp * 1000
        );
      } else {
        setCookie("AUTHORIZATION", data.token, this.state.decodedToken.exp);
        if (getCookie("keepLoggedIn")) {
          removeCookie("keepLoggedIn");
        }
      }

      axios.defaults.headers.common["Authorization"] = data.token;
      axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
    },

    logout({ commit }) {
      return new Promise(() => {
        commit("logout");
        removeCookie("AUTHORIZATION");
        removeCookie("keepLoggedIn");
        delete axios.defaults.headers.common["Authorization"];
        router.push("/login");
        document.location.reload();
      });
    },

    decodeToken({ commit }) {
      return new Promise(() => {
        commit("decodeToken");

        const splited = this.state.token.split("Bearer ");
        const item: { roles: string } = jwt_decode(splited[1]);
        localStorage.setItem("ROLE", item.roles);

        axios.defaults.headers.common["Authorization"] = this.state.token;
        axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
      });
    },

    setName({ commit }, name) {
      return new Promise(() => {
        commit("setName", name);
      });
    },

    setId({ commit }, id) {
      console.log(id);
      return new Promise(() => {
        commit("setId", id);
      });
    },

    setValidEmail({ commit }, validate) {
      return new Promise(() => {
        commit("setValidEmail", validate);
      });
    },

    verifyEmail({ commit }) {
      return new Promise(() => {
        const email = this.state.userEmail.split("@");
        if (email[1] == "yopmail.com") {
          commit("setValidEmail", false);
        } else {
          commit("setValidEmail", true);
        }
      });
    },

    setUserEmail({ commit }, email) {
      return new Promise(() => {
        commit("setUserEmail", email);
      });
    },

    setEcopoints({ commit }, totalEcopoints) {
      return new Promise(() => {
        commit("setEcopoints", totalEcopoints);
      });
    },

    setIsCompleted({ commit }, isCompleted) {
      return new Promise(() => {
        commit("setIsCompleted", isCompleted);
      });
    },

    keepLoggedIn({ commit }, keepLoggedIn) {
      const timestampNow = Date.now() * 1000;
      setCookie("keepLoggedIn", keepLoggedIn, timestampNow);
      commit("keepLoggedIn", keepLoggedIn);
    },

    updateToken({ commit }, token) {
      this.dispatch("keepLoggedIn", true);
      setCookie("AUTHORIZATION", token, this.state.decodedToken.exp * 1000);
      commit("updateToken", token);
    },
  },
  modules: {},
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    userName: (state) => state.userName,
    ecoPoints: (state) => state.ecopoints,
    gasStationName: (state) => state.gasStationName,
    validEmail: (state) => state.validEmail,
    userEmail: (state) => state.userEmail,
    userId: (state) => state.userId,
    getManagerGasStationId: (state) => state.managerGasStationId,
    getEditAttendantStatus: (state) => state.Attendant.edit,
    getEditAttendantId: (state) => state.Attendant.id,
  },
});

export default store;
