
import { Options, Vue } from 'vue-class-component';
import RouterGuards from '@/router/routesGuards';
import UserController from '@/AppClubCarby/Controller/UserController';

@Options({
  mounted() {
    this.setRoutes();
    this.verifyEmail();
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    role: function () {
      if (localStorage.getItem('token')) {
        return this.$store.state.decodedToken.roles[0];
      }
    },
    userName: function () {
      let nameSplited = this.$store.state.userName.split(' ');
      return nameSplited[0];
    },
  },
  data() {
    return {
      menu: false,
      hideUsersDropdown: false,
      hideGasStationDropdown: false,
      userService: new UserController(),
      validEmail: true,
      path: this.$route.path,
      routes: [],
      routerGuard: new RouterGuards(),
      menuHamburguer: false,
    };
  },
  methods: {
    dropdownAnimation(value) {
      if (value == 'GasStation') {
        this.hideGasStationDropdown = !this.hideGasStationDropdown;
        this.hideUsersDropdown = false;
      } else if (value == 'Users') {
        this.hideUsersDropdown = !this.hideUsersDropdown;
        this.hideGasStationDropdown = false;
      }
    },
    hideDropdown() {
      this.hideUsersDropdown = false;
      this.hideGasStationDropdown = false;
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    toggleMenu() {
      console.log('[click]');
      this.menu = !this.menu;
      this.menuHamburguer = !this.menuHamburguer;
      this.dropdown = false;
    },
    setRoutes() {
      this.routerGuard
        .getRoutes(this.$store.state.decodedToken.roles[0])
        .then((item: any) => {
          this.routes = item;
        });
    },
    getImgUrl(img: string) {
      var images = require.context('@/assets/menu-icons', false, /\.svg$/);
      return images('./' + img + '.svg');
    },
    verifyEmail() {
      if (this.$store.state.validEmail == null) {
        this.userService.getOneUserByEmail().then((res) => {
          this.$store.dispatch('setUserEmail', res.user.email);
          this.$store.dispatch('verifyEmail');
          this.validEmail = this.$store.state.validEmail;
        });
      }
    },
  },
  components: {},
})
export default class MenuApp extends Vue {}
