
import { Vue, Options } from "vue-class-component";
import CardComponent from "@/AppClubCarby/Components/CardComponent/CardComponent.vue";
import UserController from "@/AppClubCarby/Controller/UserController";
import { cpfMask, returnRole } from "@/mixins";

@Options({
  components: {
    CardComponent,
  },
  created() {
    this.userController.getUsersByType("partner").then((res) => {
      this.usersData = res.content;
    });
  },
  data() {
    return {
      userController: new UserController(),
      usersData: [],
      nameToSearch: "",
      searchingTable: true,
    };
  },
  methods: {
    cpfMask(cpf: string) {
      return cpfMask(cpf);
    },
    returnRole(role: string) {
      return returnRole(role);
    },
    returnStatus(status: boolean) {
      if (status) {
        return "ATIVO";
      } else {
        return "INATIVO";
      }
    },
    clearName() {
      const table = document.getElementById("extractInfiniteScroll");
      this.nameToSearch = "";
      if (this.typeSearch == "name") {
        this.updatingTable = true;
        table.scrollTo(0, 0);
        this.usersData = [];
        this.updateTable(0);
      }
    },

    searchByName(pag) {
      this.typeSearch = "name";
      this.updatingTable = true;
      this.usersData = [];
      console.log(pag);
      const table = document.getElementById("extractInfiniteScroll");
      let page = pag ? pag : 0;
      if (page == 0) {
        table.scrollTo(0, 0);
      }
      this.isLoading = true;

      this.searchingTable = true;

      this.userController
        .getUsersByType("partner", page, this.nameToSearch)
        .then((res) => {
          console.log("[res]", res);
          this.usersData =
            page == 0 ? [...res.content] : [...this.usersData, ...res.content];
          this.lastPage = res.last;
          this.searchingTable = false;
          this.updatingTable = false;
        })
        .catch(() => {
          this.searchingTable = false;
          this.updatingTable = false;
        });
    },
  },
})
export default class AppUsersPartnerRegisterData extends Vue {}
