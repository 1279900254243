
import { Options, Vue } from "vue-class-component";
import ModalComponent from "@/LandingPage/Components/ModalComponent/ModalComponent.vue";
import ModalHelper from "@/LandingPage/Components/ModalHelper/ModalHelper.vue";

@Options({
  components: {
    ModalComponent,
    ModalHelper,
  },
  data() {
    return {
      modalActive: false,
    };
  },
  methods: {
    openModal() {
      this.modalActive = !this.modalActive;
    },
  },
})
export default class NeedHelpComponent extends Vue {}
